import React from 'react'
import { About, FirstName, LastName, Name, Label } from './aboutYouStyles'
import { useTranslator } from '../../../hooks'

const AboutYouComponent = ({ profileInfo }) => {
  const t = useTranslator()

  return (
    <>
      <About>
        <FirstName>
          <Label
            className="overline-regular"
            data-testid="first-name-label-pfp"
          >
            {t('sections.profile.firstName')}
          </Label>
          <Name className="label" data-testid="first-name-data-pfp">
            {profileInfo.givenName || ''}
          </Name>
        </FirstName>
        <LastName>
          <Label className="overline-regular" data-testid="last-name-label-pfp">
            {t('sections.profile.lastName')}
          </Label>
          <Name className="label" data-testid="last-name-data-pfp">
            {profileInfo.familyName || ''}
          </Name>
        </LastName>
      </About>
    </>
  )
}

export default AboutYouComponent
