import React from 'react'
import { Label, ActiveSessionsContainer } from './activeSessionStyles'
import { IconExternalLink } from '../../../assets/icons'
import { TextWithLink } from '../../../components/shared-components/TextWithLink'
import { useTranslator } from '../../../hooks'
import { publishHyperLinkClickedEvent } from '../../../utils/analytics'
import getMyAccountUrl from '../../../utils/urlResolver'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LoadingIndicator } from '../styles'

const ActiveSessionComponent = ({ analytics, stack, isLoading }) => {
  const t = useTranslator()
  const flags = useFlags()
  const hideHpLinks = flags['hpxHideHpIdLinksInProfile']
  const sendTrackingId = flags['hpxMyAccountProfileTrackingId']

  const manageSessionsHref = `${getMyAccountUrl(stack)}/security`
  const sendManageSessionsLinkEvent = () => {
    publishHyperLinkClickedEvent(
      analytics,
      'ManageActiveSessions',
      manageSessionsHref,
      sendTrackingId
    )
  }

  return (
    <ActiveSessionsContainer>
      <Label className="title-small" data-testid="active-sessions-title-pfp">
        {t('sections.activeSessions.header')}
      </Label>
      {isLoading ? (
        <LoadingIndicator appearance="linear" behavior="indeterminate" />
      ) : (
        <>
          {!hideHpLinks && (
            <TextWithLink
              typographyClass="caption"
              text={t('sections.activeSessions.description')}
              replacementObject={{
                initialKey: '{{tagReplaceStart}}',
                finalKey: '{{tagReplaceEnd}}',
                href: manageSessionsHref
              }}
              endIcon={<IconExternalLink size={20} />}
              handleClickLink={sendManageSessionsLinkEvent}
            />
          )}
        </>
      )}
    </ActiveSessionsContainer>
  )
}
export default ActiveSessionComponent
