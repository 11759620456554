import React, { useEffect, useContext, useState } from 'react'
import AboutYouComponent from './AboutYouComponent/aboutYou'
import LoginInformationComponent from './LoginInformationComponent/loginInformation'
import ActiveSessionComponent from './ActiveSessionComponent/activeSession'
import {
  publishScreenDisplayedEvent,
  publishHyperLinkClickedEvent
} from '../../utils/analytics'
import { useTranslator } from '../../hooks'
import {
  Container,
  ProfileHeader,
  TextContainer,
  Descriptions,
  LoadingIndicator
} from './styles'
import { TextWithLink } from '../shared-components/TextWithLink'
import { StratusContext } from '../../contexts/stratusContext'
import { IconExternalLink } from '../../assets/icons'
import getMyAccountUrl from '../../utils/urlResolver'
import PageError from './ErrorPage'
import { useFlags } from 'launchdarkly-react-client-sdk'

const ProfileComponent = ({ analytics, stack }) => {
  const manageProfileHref = `${getMyAccountUrl(stack)}/profile`
  const privacyPolicyHref = 'https://www.hp.com/us-en/privacy/privacy.html'
  const t = useTranslator()
  const { userTenantMgtSvcClient } = useContext(StratusContext)
  const [userData, setUserData] = useState({
    data: {},
    isLoading: true,
    isFailed: false
  })
  const flags = useFlags()
  const hideHpLinks = flags['hpxHideHpIdLinksInProfile']
  const sendTrackingId = flags['hpxMyAccountProfileTrackingId']

  const sendManageProfileLinkEvent = () => {
    publishHyperLinkClickedEvent(
      analytics,
      'ManageAccount',
      manageProfileHref,
      sendTrackingId
    )
  }

  const sendPrivacyPolicyLinkEvent = () => {
    publishHyperLinkClickedEvent(
      analytics,
      'PrivacyPolicy',
      privacyPolicyHref,
      sendTrackingId
    )
  }

  useEffect(() => {
    publishScreenDisplayedEvent(analytics, sendTrackingId)
  }, [analytics, sendTrackingId])

  const getProfileData = async () => {
    setUserData({ ...userData, isLoading: true, isFailed: false })
    let profileData = {}
    try {
      const profileDataResponse = await userTenantMgtSvcClient.getCurrentActiveUserWithTenant()
      profileData = profileDataResponse.data.user
      if (
        profileDataResponse &&
        profileDataResponse.data &&
        profileDataResponse.data.user
      ) {
        setUserData({
          ...userData,
          isLoading: false,
          data: profileData,
          isFailed: false
        })
      } else {
        setUserData({ ...userData, isFailed: true, isLoading: false })
      }
    } catch (e) {
      setUserData({ ...userData, isFailed: true, isLoading: false })
    }
  }

  useEffect(() => {
    if (!userData.isFailed) {
      getProfileData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (userData.isFailed) {
    return <PageError refreshHandler={getProfileData} />
  }

  return (
    <Container data-testid="profile-mfe-container">
      <ProfileHeader className="title-small" data-testid="profile-title-pfp">
        {t('sections.profile.header')}
      </ProfileHeader>
      {userData.isLoading ? (
        <LoadingIndicator appearance="linear" behavior="indeterminate" />
      ) : (
        <>
          <Descriptions>
            <TextContainer>
              {!hideHpLinks && (
                <TextWithLink
                  typographyClass="caption"
                  text={t('sections.profile.descriptions.description1')}
                  replacementObject={{
                    initialKey: '{{tagReplaceStart}}',
                    finalKey: '{{tagReplaceEnd}}',
                    href: manageProfileHref
                  }}
                  endIcon={<IconExternalLink size={20} />}
                  handleClickLink={sendManageProfileLinkEvent}
                />
              )}
            </TextContainer>
            {!hideHpLinks && (
              <TextWithLink
                typographyClass="caption"
                text={t('sections.profile.descriptions.description2')}
                replacementObject={{
                  initialKey: '{{tagReplaceStart}}',
                  finalKey: '{{tagReplaceEnd}}',
                  href: privacyPolicyHref
                }}
                handleClickLink={sendPrivacyPolicyLinkEvent}
              />
            )}
          </Descriptions>
          <AboutYouComponent profileInfo={userData.data} />
        </>
      )}
      <LoginInformationComponent
        profileInfo={userData.data}
        analytics={analytics}
        stack={stack}
        isLoading={userData.isLoading}
      />
      <ActiveSessionComponent
        analytics={analytics}
        stack={stack}
        isLoading={userData.isLoading}
      />
    </Container>
  )
}

export default ProfileComponent
